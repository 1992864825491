<template>
  <div id="customersUploadPageV2">
    <!-- Header -->
    <div class="header mb-3 mt-1">
      <span class="text-2xl font-semibold">Import Customers</span>

      <div class="filters" style="float: right">
        <router-link to="/segment/create">
          <el-button type="primary" size="mini" plain @click="downloadWithCSVFile()">
            <i class="fa-solid fa-download"></i>
            Download Sample CSV File
          </el-button>
        </router-link>
      </div>
    </div>

    <!-- File Select Section -->
    <div v-show="currentSection == 'fileSelect'" class="overflow-hidden border border-gray-100 sm:rounded-lg mb-4">
      <!-- Upload Form -->
      <div class="bg-white border-b border-gray-200 p-3">
        <div class="text-gray-700 text-xs pb-1">CSV File</div>
        <el-upload ref="upload" action="" :http-request="addAttachment" :multiple="false" :show-file-list="false">
          <span class="uploadedFileName"> {{ inputForm.csvFile ? inputForm.csvFile.name : '' }}</span>
          <el-button plain slot="trigger" size="mini" type="primary"> {{ inputForm.csvFile ? 'Change' : 'Select' }} CSV File </el-button>

          <div class="border-l-4 border-yellow-400 bg-yellow-50 py-2 px-3 mt-3 rounded-sm">
            <div class="text-yellow-700">
              <div class="mb-0 font-semibold">Notes</div>
              <div class="text-sm leading-6">
                <div><i class="fa-regular fa-arrow-right"></i> &nbsp; Don't specify headers in csv.</div>
                <div><i class="fa-regular fa-arrow-right"></i> &nbsp; Maximum 25 columns are allowed.</div>
                <div><i class="fa-regular fa-arrow-right"></i> &nbsp; Maximum 50MB File can be uploaded.</div>
                <div><i class="fa-regular fa-arrow-right"></i> &nbsp; System Id Should be present in csv to make sure duplicate customers are not created.</div>
              </div>
            </div>
          </div>
        </el-upload>
      </div>

      <!-- Purpose Of This Upload -->
      <div class="bg-white border-b border-gray-200 p-3">
        <div class="text-gray-700 text-xs pb-2">Purpose Of Upload</div>
        <el-radio-group v-model="inputForm.purposeOfUpload" size="mini" @change="staticListErrorMsg = ''">
          <el-radio label="none" border>Just Upload</el-radio>
          <el-radio label="new_list" border>Upload & Add To New Static List </el-radio>
          <el-radio label="existing_list" border>Upload & Add To Existing Static List</el-radio>
        </el-radio-group>

        <!-- Existing Static Lists -->
        <template v-if="inputForm.purposeOfUpload == 'existing_list'">
          <div class="text-gray-700 text-xs pb-2">Select Static List</div>
          <el-select v-model="inputForm.selectedStaticSegment" filterable placeholder="Select Static List">
            <el-option v-for="list in staticSegmentList" :key="list.id" :label="list.name" :value="list.id"> </el-option>
          </el-select>
          <div class="inputErrorMsg" v-if="staticListErrorMsg">{{ staticListErrorMsg }}</div>
        </template>

        <!-- New Static List Name -->
        <div class="mt-3" v-if="inputForm.purposeOfUpload == 'new_list'">
          <div class="text-gray-700 text-xs pb-2">Static List Name</div>
          <el-input v-model="inputForm.newStaticSegmentName" placeholder="Static List Name"></el-input>
          <div class="inputErrorMsg" v-if="staticListErrorMsg">{{ staticListErrorMsg }}</div>
        </div>
      </div>

      <!-- Footer -->
      <div class="bg-white p-3">
        <el-button type="primary" @click="onFormSubmit" :loading="validatingCSVFile">
          Continue
          <i class="el-icon-arrow-right el-icon-right"></i>
        </el-button>
      </div>
    </div>

    <!-- Column Mapping Section -->
    <div v-show="currentSection != 'fileSelect'" class="overflow-hidden sm:rounded-lg mb-4 border">
      <!-- Header -->
      <div class="py-2 px-3 border-b">
        <span @click="currentSection = 'fileSelect'" class="leading-7 text-sm text-primary-500 font-semibold cursor-pointer"><i class="fa-regular fa-arrow-left pr-1"></i>Change CSV File</span>
      </div>

      <!-- Error List -->
      <div class="p-3 bg-red-50 text-red-600" v-if="validationErrorList.length > 0">
        <div class="font-semibold">Failed to upload the CSV due to invalid csv fields.</div>

        <ul class="text-sm pl-3 pt-2">
          <li class="errorInfo" v-for="(item, index) in validationErrorList" :key="index" :value="item.value">
            <span class="errorRow" v-if="item.row"><span class="label"> Row </span>#{{ item.row }},</span>
            <span class="errorColumn" v-if="item.column"><span class="label"> Column</span> #{{ item.column }},</span>
            <span class="errorMessage"><span class="label"> </span> {{ item.message }}</span>
          </li>
        </ul>
      </div>

      <div>
        <div class="border-b bg-white border-b overflow-hidden" v-for="(column, index) in columnList" :key="index">
          <el-row :gutter="10">
            <!-- Left Side - Column Defination -->
            <el-col :span="16" class="mb-2">
              <div class="mx-4 mt-3 mb-0 text-lg font-semibold text-black">Column {{ index + 1 }}</div>

              <div class="mx-4 my-2">
                <div class="mt-2.5">
                  <div class="text-gray-700 text-xs pb-1">Select Attribute To Map</div>
                  <div class="userAttributeDivision">
                    <el-select class="w-72" size="small" prop="userAttribute" v-model="column.columnName" filterable placeholder="Select User Property" @change="onColumnMapChange(column, index)">
                      <el-option v-for="(item, index) in customerMetadata" :key="index" :label="item.name" :value="item.columnName">
                        <span style="float: left">{{ item.name }}&nbsp;&nbsp;</span>
                        <span v-if="item.type == 'decimal'" style="float: right; color: #8492a6; font-size: 13px">NUMBER</span>
                        <span v-else style="float: right; color: #8492a6; font-size: 13px">{{ item.typeDisplayText }}</span>
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>

              <!-- Error Message -->
              <div class="mx-4 my-3 text-red-600 bg-red-100 rounded px-2 py-1 text-sm" v-if="column.error.status">{{ column.error.message }}</div>
            </el-col>

            <!-- Right Side - Column Preview -->
            <el-col :span="8">
              <div class="m-3 mr-4 border-b-0 border w-60 overflow-hidden rounded-md float-right">
                <div class="py-1 px-2 text-xs border-b" v-for="(value, index1) in column.valueList" :key="index1">
                  <span v-if="value">{{ value }}</span>
                  <span v-else>&nbsp;</span>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="border-b bg-white border-b overflow-hidden text-center py-3">
        <el-button @click="onStartUpload" class="w-80" type="primary">Start Upload</el-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./customersUploadV2.scss"></style>

<script>
import CustomersUploadComponent from './customersUploadV2Component';
export default CustomersUploadComponent;
</script>
